<div class="flex justify-between">
  <p data-test-id="acceptance-withdrawal-blocked-dialog-body">
    Approval cannot be withdrawn for experts with scheduled calls. To cancel this call, please contact your Techspert
    project management team.
  </p>

  <button mat-icon-button mat-dialog-close data-test-id="select-availability-cancel-button">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div class="flex flex-col">
  <div class="flex gap-2 justify-center h-full w-full min-h-full min-w-full">
    <button mat-flat-button mat-dialog-close color="primary" class="basis-1/2"
      data-test-id="acceptance-withdrawal-blocked-dialog-close-button">Close Window</button>
  </div>
</div>