<div class="flex justify-between content-center">
  <h3 mat-dialog-title class="primary-color" data-test-id="select-availability-dialog-title">Accept expert</h3>
  <button mat-icon-button mat-dialog-close data-test-id="select-availability-cancel-button">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<mat-dialog-content class="flex flex-col">
  <p data-test-id="select-availability-dialog-body">
    <span *ngIf="data.readyToAccept.isReady">
      Accept expert for {{data.availability | availabilityDate}} {{data.availability | availabilityTime}}?
    </span>
    <span *ngIf="!data.readyToAccept.isReady">
      {{errorMessage}}
    </span>
  </p>
</mat-dialog-content>

<mat-dialog-actions class="flex gap-2">
  <button mat-stroked-button color="primary" mat-dialog-close class="flex-1"
    data-test-id="select-availability-dialog-close-button">Cancel</button>
  <button *ngIf="data.readyToAccept.isReady" mat-flat-button color="primary" (click)="confirm()" class="flex-1"
    data-test-id="select-availability-dialog-confirm-button">
    Confirm
  </button>
  <button *ngIf="!data.readyToAccept.isReady" mat-flat-button color="primary" (click)="reload()" class="flex-1"
    data-test-id="select-availability-dialog-refresh-button">
    Refresh
  </button>
</mat-dialog-actions>