<div class="omnisearch-item flex grow gap-2">
  <div class="result-container flex flex-col gt-sm:flex-row gap-2">
    <div class="info flex grow gap-3 justify-start content-start">
      <div class="flex flex-col justify-start content-center" style="min-width: 48px">
        <img src="../../../../../assets/images/undiscovered_expert.svg" matTooltip="Undiscovered Expert">
      </div>
      <div class="inner-container flex flex-col grow gap-2">
        <div class="flex justify-start content-center">
          <div class="title" [innerHTML]="omnisearchResponse.name | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-name">
          </div>
        </div>
        <div class="geo-expertise">
          <div class="geo-profile flex gap-2 justify-start items-center"
            *ngIf="omnisearchResponse.geographicTarget && omnisearchResponse.profileType"
            data-test-id="omnisearch-result-geo-target-and-profile-type">
            <span [innerHTML]="omnisearchResponse.geographicTarget | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-geo-target"></span>
            <span [innerHTML]="omnisearchResponse.profileType | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-profile-type"></span>
          </div>
          <div class="expertise" *ngFor="let expertise of omnisearchResponse.expertise"
            [innerHTML]="expertise | lowercase | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-expertise">
          </div>
        </div>
      </div>
    </div>
    <div class="bio-container flex justify-start content-start gap-2"
      *ngIf="omnisearchResponse.portalAffiliations.length" data-test-id="omnisearch-result-expert-bio">
      <div class="affiliation" [innerHTML]="omnisearchResponse.portalAffiliations | safeHtml">
      </div>
    </div>
    <div class="button-container flex flex-col gap-2 justify-start content-start">
      <button mat-flat-button class="flex grow"
        [ngClass]="{'primary': !engagementRequested, 'success': engagementRequested}" (click)="requestEngagement()"
        [disabled]="engagementRequested" data-test-id="omnisearch-result-request-engagement-button">
        <span *ngIf="engagementRequested">Call requested</span>
        <span *ngIf="!engagementRequested">Request a call</span>
      </button>

      <button mat-stroked-button class="flex grow" (click)="requestInformation()"
        data-test-id="omnisearch-result-request-information-button">
        <span class="stroked">Request information</span>
      </button>
    </div>
  </div>
</div>