import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ExpertsService,
  ExpertUpdate,
  ExpertUpdateType,
} from '@techspert-io/experts';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { IExpert } from '../models/expert.interface';

export type PortalPhases =
  | 'newExperts'
  | 'acceptedExperts'
  | 'completedConnections'
  | 'declinedExperts';

export interface IExpertPhases extends Record<string, IExpert[]> {
  newExperts: IExpert[];
  acceptedExperts: IExpert[];
  completedConnections: IExpert[];
  declinedExperts: IExpert[];
}

@Injectable({ providedIn: 'root' })
export class ExpertStoreService {
  private currOppInner$ = new ReplaySubject<string>(1);
  private updatedExpertsInner$ = new Subject<ExpertUpdate>();
  private expertLoadingIdsInner$ = new BehaviorSubject<Record<string, boolean>>(
    {}
  );
  private loadingOppExpertsInner$ = new BehaviorSubject<boolean>(true);

  private expertsInner$ = this.currOppInner$.pipe(
    tap(() => this.loadingOppExpertsInner$.next(true)),
    switchMap((oppId) => this.expertService.getExpertsByOpportunityId(oppId)),
    tap(() => this.loadingOppExpertsInner$.next(false))
  );

  private updatedExperts$ = this.updatedExpertsInner$.pipe(
    tap((p) => this.expertLoadingIdsInner$.next({ [p.expertId]: true })),
    mergeMap((p) =>
      this.expertUpdateImpl(p).pipe(
        catchError(() => of(undefined)),
        tap(() => this.expertLoadingIdsInner$.next({ [p.expertId]: false }))
      )
    ),
    scan(
      (prev, curr) => ({ ...prev, ...(curr && { [curr.expertId]: curr }) }),
      {}
    ),
    startWith<Record<string, IExpert>>({})
  );

  private allExperts$ = combineLatest([
    this.expertsInner$,
    this.updatedExperts$,
  ]).pipe(
    map(([experts, updates]) =>
      experts.map((e) => ({
        ...(updates[e.expertId] || e),
      }))
    )
  );

  expertLoadingIds$ = this.expertLoadingIdsInner$.pipe(
    scan<Record<string, boolean>, Record<string, boolean>>(
      (acc, curr) => ({ ...acc, ...curr }),
      {}
    )
  );

  experts$ = this.allExperts$.pipe(
    map((experts) => this.mapExpertsToPhases(experts)),
    shareReplay(1)
  );

  loadingOppExperts$ = this.loadingOppExpertsInner$.asObservable();

  constructor(
    private toastService: ToastrService,
    private expertService: ExpertsService
  ) {}

  setOpportunityId(opportunityId: string): void {
    this.currOppInner$.next(opportunityId);
  }

  updateExpert(request: ExpertUpdate): void {
    this.updatedExpertsInner$.next(request);
  }

  private expertUpdateImpl(request: ExpertUpdate): Observable<IExpert> {
    const makeReq = (request: ExpertUpdate) => {
      switch (request.type) {
        case ExpertUpdateType.ConfirmAvailability: {
          return this.expertService.confirmAvailability(
            request.expertId,
            request.payload
          );
        }
        case ExpertUpdateType.RequestDifferentTime: {
          return this.expertService.requestAnotherTime(
            request.expertId,
            request.payload
          );
        }
        case ExpertUpdateType.SeenByClient: {
          return this.expertService.seenByClient(request.expertId);
        }
        case ExpertUpdateType.UpdateClientNotes: {
          return this.expertService.updateClientNotes(
            request.expertId,
            request.payload
          );
        }
        case ExpertUpdateType.ExpertOnHold: {
          return this.expertService
            .updateExpertOnHold(request.expertId, request.payload)
            .pipe(
              tap(() =>
                this.toastService.success(
                  'Thank you - your feedback has been sent to the team.',
                  request.payload.onHold ? 'Expert on hold' : 'Expert off hold'
                )
              )
            );
        }
        case ExpertUpdateType.ExpertRejection: {
          return this.expertService
            .updateExpertRejection(request.expertId, request.payload)
            .pipe(
              tap(() =>
                this.toastService.success(
                  'Thank you - your feedback has been sent to the team.',
                  request.payload.clientRejected
                    ? 'Expert rejected'
                    : 'Expert unrejected'
                )
              )
            );
        }
        case ExpertUpdateType.FavouriteExpert: {
          return this.expertService.updateExpertFavourite(
            request.expertId,
            request.payload
          );
        }
        case ExpertUpdateType.ExpertApproval: {
          return this.expertService
            .updateExpertApproval(request.expertId, request.payload)
            .pipe(
              tap(
                (res) =>
                  res.clientApproved &&
                  this.toastService.success(
                    'This expert meets compliance requirements and may be scheduled',
                    'Expert approved'
                  )
              ),
              catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                  if (err.status === 403) {
                    this.toastService.error(
                      'You must be a compliance team member to approve experts',
                      'Permission denied'
                    );
                  }
                }

                return throwError(err);
              })
            );
        }
        case ExpertUpdateType.ExpertSchedule:
          return this.expertService
            .scheduleExpert(request.expertId, request.payload)
            .pipe(
              tap(() =>
                this.toastService.success(
                  'Conference booked',
                  'Expert scheduled'
                )
              )
            );
        case ExpertUpdateType.RequestBio:
          return this.expertService
            .requestExpertBio(request.expertId)
            .pipe(tap(() => this.toastService.success('Expert bio requested')));
      }
    };

    return makeReq(request).pipe(
      catchError((err) => {
        console.error(err);

        if (
          !(
            request.type === ExpertUpdateType.ExpertApproval &&
            err instanceof HttpErrorResponse &&
            err.status === 403
          )
        ) {
          this.toastService.error('Failed to update expert');
        }

        return throwError(new Error('Expert update failed'));
      })
    );
  }

  private mapExpertsToPhases(filteredExperts: IExpert[]): IExpertPhases {
    const byConnectPhases =
      (phases: string[]) =>
      (expert: IExpert): boolean =>
        phases.includes(expert.connectPhase) && !expert.clientRejected;

    const newExperts = filteredExperts.filter(
      byConnectPhases(['sentToClient'])
    );
    const acceptedExperts = filteredExperts.filter(
      byConnectPhases(['accepted', 'scheduled'])
    );
    const completedConnections = filteredExperts.filter(
      byConnectPhases(['completed'])
    );
    const declinedExperts = filteredExperts.filter(
      (expert) => expert.clientRejected
    );

    return {
      newExperts,
      acceptedExperts,
      completedConnections,
      declinedExperts,
    };
  }
}
