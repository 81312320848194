import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MissingRefreshTokenError } from '@auth0/auth0-angular';
import { GrowthBook } from '@growthbook/growthbook';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subject, combineLatest, from, fromEvent, merge } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CognitoAuthService } from './features/auth/services/cognito-auth.service';
import { LOCAL_STORAGE } from './features/auth/services/token';
import { GROWTHBOOK } from './features/growthbook/token';
import { OmnisearchService } from './features/omnisearch/services/omnisearch.service';

export const GA_MEASUREMENT_ID = new InjectionToken('GA_MEASUREMENT_ID');

interface IWindow {
  hj: (...params: (string | unknown)[]) => void;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
  interface Window extends IWindow {}
}

interface IBanner {
  id: string;
  text: string;
  cta: string;
  link: string;
  expires: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private initialisedHotjar$ = new Subject();

  showClose = true;

  banner: IBanner = null;

  omnisearchData$ = combineLatest([
    this.omnisearchService.omnisearchResults$,
    this.omnisearchService.recommendedExperts$,
    this.omnisearchService.queryType$,
    this.omnisearchService.viewingOmnisearchProjectName$,
    this.omnisearchService.omnisearchFocused$,
  ]).pipe(
    map(
      ([
        omnisearchResults,
        recommendedExperts,
        queryType,
        omnisearchProjectName,
        omnisearchFocused,
      ]) => ({
        omnisearchResults,
        recommendedExperts,
        queryType,
        omnisearchProjectName,
        omnisearchFocused,
      })
    )
  );

  constructor(
    private router: Router,
    private cognitoAuthService: CognitoAuthService,
    private gaService: GoogleAnalyticsService,
    private omnisearchService: OmnisearchService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(GA_MEASUREMENT_ID) private gaMeasurementId: string,
    @Inject(GROWTHBOOK) private growthbook: GrowthBook,
    @Inject(LOCAL_STORAGE) private localStorage: Storage
  ) {}

  ngOnInit(): void {
    from(this.growthbook.init()).subscribe(() => {
      this.banner = this.initialiseBanner();
    });

    combineLatest([
      this.initialisedHotjar$,
      this.cognitoAuthService.loggedInConnect$.pipe(filter((u) => !!u)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([, u]) => {
        window.hj('identify', u.id, { email: u.email });
      });

    this.setupAnalytics();

    this.cognitoAuthService.authError$
      .pipe(takeUntil(this.destroy$))
      .subscribe((err) => {
        if (
          err instanceof MissingRefreshTokenError ||
          err.message === 'Unknown or invalid refresh token.'
        ) {
          this.cognitoAuthService.logOut();
        } else {
          this.router.navigate(['not-authorised'], {
            queryParams: { message: err.message },
          });
        }
      });

    fromEvent(window, 'unload')
      .pipe(
        first(() =>
          ['/connect', '/call-complete'].some((url) =>
            this.router.url.startsWith(url)
          )
        )
      )
      .subscribe(() => this.cognitoAuthService.setRedirectUrl(this.router.url));

    const routerNavigation$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e) => e as NavigationEnd)
    );

    routerNavigation$.subscribe((e) => {
      if (!e.url.startsWith('/connect')) {
        this.omnisearchService.setViewedProject('');
      }

      const visitedRoot = e.url.startsWith('/search');
      if (visitedRoot) {
        this.omnisearchService.clearQuery();
        this.omnisearchService.clearRecommendedExperts();
        this.omnisearchService.setFocused(true);
      } else {
        this.omnisearchService.setFocused(false);
      }

      this.showClose = !visitedRoot;
    });

    combineLatest([
      routerNavigation$.pipe(first()),
      this.cognitoAuthService
        .initAuth()
        .pipe(
          switchMap(() =>
            combineLatest([
              this.omnisearchService.isOmnisearchFeatureEnabled(
                'omnisearchEnabled'
              ),
              this.omnisearchService.isOmnisearchFeatureEnabled(
                'omnisearchGlobalEnabled'
              ),
            ])
          )
        ),
    ])
      .pipe(takeUntil(merge(this.cognitoAuthService.authError$, this.destroy$)))
      .subscribe(([e, [omnisearchEnabled, omnisearchGlobalEnabled]]) => {
        this.omnisearchService.setEnabled(omnisearchEnabled);
        this.omnisearchService.setGlobalEnabled(omnisearchGlobalEnabled);

        const redirectUrl = this.cognitoAuthService.getRedirectUrl();

        const [, baseURL] = e.url.split('/');

        if (
          redirectUrl &&
          ![
            '',
            'search',
            'not-found',
            'call-complete',
            'assistant',
            'screener-comparison',
          ].includes(baseURL) &&
          !e.url.startsWith('/connect')
        ) {
          this.router.navigate([redirectUrl], {
            replaceUrl: true,
          });
        } else if (e.url.startsWith('/secure')) {
          this.router.navigate(['/']);
        }
        this.cognitoAuthService.setRedirectUrl(null);
      });
  }

  private initialiseBanner() {
    const banner = this.growthbook.getFeatureValue<IBanner>(
      'client-portal-banner',
      null
    );

    if (!banner) {
      return null;
    }

    const closedThisBanner = this.localStorage.getItem(
      `closed-banner-${banner.id}`
    );

    const bannerExpired = new Date(banner.expires).getTime() < Date.now();

    return closedThisBanner || bannerExpired ? null : banner;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  closeBanner(): void {
    this.localStorage.setItem(`closed-banner-${this.banner.id}`, 'true');
    this.banner = null;
  }

  private setupAnalytics(): void {
    this.gaService.gtag('consent', 'default', {
      analytics_storage: 'granted',
    });

    this.document.defaultView[`ga-disable-${this.gaMeasurementId}`] = false;

    if (environment.production) {
      this.loadHotjarScript();
      this.initialisedHotjar$.next(true);
    }
  }

  private loadHotjarScript(): void {
    const node = document.createElement('script');
    node.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:3673023,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
