import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ExpertsService } from '@techspert-io/experts';
import { IExpertFile } from '@techspert-io/files';
import { Observable, combineLatest } from 'rxjs';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import {
  Add,
  Delete,
  ExpertFilesStateService,
  UpdateProgress,
} from '../../../../../shared/services/filestore-state.service';
import {
  FileStoreService,
  IFileProgressEvent,
} from '../../../../../shared/services/filestore.service';
import { IOmnisearchExpert } from '../../../models/omnisearch.models';
import { OmnisearchService } from '../../../services/omnisearch.service';

interface IAnimationState {
  favouriteState: 'bounceStart' | 'bounceEnd';
}

@Component({
  selector: 'app-client-result',
  templateUrl: './client-result.component.html',
  styleUrls: ['../omnisearch-result.component.scss'],
  animations: [
    trigger('bounce', [
      state(
        'bounceStart',
        style({
          transform: 'scale(1)',
        })
      ),
      state(
        'bounceEnd',
        style({
          transform: 'scale(1)',
        })
      ),
      transition(
        'bounceStart => bounceEnd',
        animate(
          '100ms ease-in',
          keyframes([
            style({ transform: 'scale(0.7)' }),
            style({ transform: 'scale(1)' }),
          ])
        )
      ),
    ]),
  ],
})
export class ClientResultComponent {
  animationState: IAnimationState = { favouriteState: 'bounceEnd' };

  @Input() omnisearchResponse: IOmnisearchExpert;
  @Input() engagementRequested: boolean;
  @Output() requestExpertEngagement = new EventEmitter<IOmnisearchExpert>();

  loadingFiles$ = this.filesState.state$;
  downloadedFiles: Record<string, boolean> = {};
  showAttachments = false;
  isUpdating = false;

  get searchTerm(): string {
    return this.omnisearchService.searchForm.get('search').value;
  }

  constructor(
    private router: Router,
    private expertsService: ExpertsService,
    private omnisearchService: OmnisearchService,
    private filesState: ExpertFilesStateService,
    private fileStore: FileStoreService
  ) {}

  requestEngagement(): void {
    this.requestExpertEngagement.emit(this.omnisearchResponse);
  }

  bounceEnd(): void {
    this.animationState.favouriteState = 'bounceEnd';
  }

  onToggleFavourited(): void {
    if (this.isUpdating) {
      return;
    }

    this.animationState.favouriteState = 'bounceStart';
    this.isUpdating = true;

    this.expertsService
      .updateExpertFavourite(this.omnisearchResponse.id, {
        favourited: !this.omnisearchResponse.favourited,
      })
      .pipe(
        tap((exp) => (this.omnisearchResponse.favourited = exp.favourited)),
        finalize(() => (this.isUpdating = false))
      )
      .subscribe();
  }

  navigateToExpert(entity: IOmnisearchExpert): void {
    this.omnisearchService.setFocused(false);
    this.omnisearchService.clearRecommendedExperts();

    this.router.navigate(['connect', entity.oppId], {
      skipLocationChange: false,
      queryParams: { expertId: entity.id },
    });
  }

  downloadAll(files: IExpertFile[]): void {
    combineLatest(files.map((f) => this.downloadFile(f))).subscribe();
  }

  download(file: IExpertFile): void {
    this.downloadFile(file).subscribe();
  }

  private downloadFile(file: IExpertFile): Observable<IFileProgressEvent> {
    this.filesState.dispatch(new Add(file.fileKey));
    return this.fileStore
      .downloadExpertFile(
        file.fileKey,
        `${file.fileName}.${file.fileExtension}`
      )
      .pipe(
        takeUntil(this.loadingFiles$.pipe(filter((d) => !d[file.fileKey]))),
        tap((event) =>
          this.filesState.dispatch(
            new UpdateProgress(file.fileKey, event.percentageComplete)
          )
        ),
        finalize(() => {
          this.downloadedFiles[file.fileKey] = true;
          this.filesState.dispatch(new Delete(file.fileKey));
        })
      );
  }
}
