<div class="opportunity-view">
  <ng-container *ngIf="opportunity$ | async as opp; else noOppTmpl">
    <ng-container [ngTemplateOutlet]="opportunityTmpl" [ngTemplateOutletContext]="{opp}"></ng-container>
  </ng-container>
</div>

<ng-template #noOppTmpl>
  <ng-container *ngIf="!forceLogin" [ngTemplateOutlet]="loader"></ng-container>
  <ng-container *ngIf="forceLogin" [ngTemplateOutlet]="loginTmpl"></ng-container>
</ng-template>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #loginTmpl>
  <div class="login-container flex flex-col justify-start content-center" data-test-id="opportunity-login">
    <div class="flex flex-col justify-start content-center">
      <app-empty-state-compliance-svg></app-empty-state-compliance-svg>
      <h1 data-test-id="opportunity-compliance-logged-out-title">
        To meet compliance requirements you need to be logged in to see this page
      </h1>

      <p data-test-id="opportunity-compliance-logged-out-message">
        Login, or contact your project manager to set up your account.
      </p>

      <button mat-flat-button color="primary" class="icon-button" routerLink='/secure'
        data-test-id="opportunity-compliance-logged-out-login-button">
        <mat-icon class="secondaryColor">login</mat-icon>
        <span>Login</span>
      </button>

      <p data-test-id="opportunity-compliance-logged-out-faq-message">
        Any questions? Visit our
        <a href="https://www.techspert.com/portal-faqs" target="_blank"
          data-test-id="opportunity-compliance-logged-out-faq-link"> help portal.</a>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #opportunityTmpl let-opp="opp">

  <ng-container *ngIf="vmExperts$ | async as vmE; else loader">
    <app-loader *ngIf="vmE.loading"></app-loader>

    <div *ngIf="!vmE.loading" class="top-section-container">
      <div class="description-div flex flex-col gap-2">
        <div class="flex flex-col justify-between content-center gap-2 gt-sm:flex-row gt-sm:gap-0">
          <div class="flex flex-col gap-2">
            <h2 data-test-id="opportunity-welcome-header">
              Welcome to your project portal for {{opp.opportunityName}}
            </h2>

            <div *ngIf="showDescription" class="flex flex-col description">
              <span data-test-id="opportunity-welcome-description">
                You currently have
                <span *ngIf="vmE.experts.newExperts.length !== 1">
                  {{vmE.experts.newExperts.length}} new profiles
                </span>
                <span *ngIf="vmE.experts.newExperts.length === 1">
                  {{vmE.experts.newExperts.length}} new profile
                </span>
                <span> ready to review and </span>
                <span *ngIf="vmE.experts.acceptedExperts.length !== 1">
                  {{vmE.experts.acceptedExperts.length}} calls
                </span>
                <span *ngIf="vmE.experts.acceptedExperts.length === 1">
                  {{vmE.experts.acceptedExperts.length}} call
                </span>
                scheduled to take place.
              </span>
              <span>
                You can review progress against all of your segments in real-time in the Project Summary tab below.
              </span>
            </div>
          </div>
          <div class="flex flex-col gap-2 justify-center content-center">
            <button mat-stroked-button color="primary" class="icon-button primaryColor-border"
              [appExperts]="vmE.experts" [opportunityName]="opp.opportunityName"
              [matTooltip]="isFileDownloadAuthorised ? 'Download all profiles' : 'Please log in to download files'"
              matTooltipPosition="left" [isFileDownloadAuthorised]="isFileDownloadAuthorised"
              [isExpertApprovalRequired]="vmE.expertApprovalRequired" data-test-id="opportunity-export-profiles">
              <mat-icon class="secondaryColor">ic_file_download</mat-icon>
              <span data-test-id="opportunity-export-profiles-label">Export profiles</span>
            </button>
            <button mat-flat-button color="primary" class="icon-button primaryColor-border"
              *ngIf="opp.screenerComparisonEnabled"
              [matTooltip]="isFileDownloadAuthorised ? 'Compare profile screeners' : 'Please log in to compare screeners'"
              (click)="navigateScreener(opp.opportunityId)" data-test-id="opportunity-compare-screeners">
              <mat-icon class="secondaryColor">compare_arrows</mat-icon>
              <span data-test-id="opportunity-compare-screeners-label">Compare screeners</span>
            </button>
          </div>
        </div>
        <div *ngIf="vmE.expertApprovalRequired">
          <ng-container [ngTemplateOutlet]="approvalRequiredBannerTmpl"></ng-container>
        </div>
      </div>

      <mat-tab-group class="main-tab" color="primary" id="tab-group" [selectedIndex]="selectedIndex" mat-stretch-tabs
        (selectedTabChange)="selectedTabChange($event.index)" data-test-id="opportunity-header-group">
        <mat-tab *ngIf="showTrackingTable">
          <ng-template mat-tab-label>
            <span data-test-id="opportunity-project-summary-tab">Project Summary</span>
          </ng-template>
          <div class="new-experts">
            <app-geographic-progress (adjustTabIndex)="adjustTabIndex($event)"
              (openOmnisearch)="openOmnisearchFromProjectProgressPage()" [opportunity]="opp"
              [isAuthorised]="isFileDownloadAuthorised">
            </app-geographic-progress>
          </div>
        </mat-tab>

        <mat-tab *ngIf="vmE.experts.newExperts as newExperts">
          <ng-template mat-tab-label>
            <span data-test-id="opportunity-new-profiles-tab">New Profiles</span>
            <span class="count secondaryColorBG primaryColor" *ngIf="newExperts.length"
              data-test-id="opportunity-new-profiles-count">{{newExperts.length}}</span>
          </ng-template>

          <div class="new-experts">
            <app-expert-list [expertLoadingIds]="expertLoadingIds$ | async" [experts]="newExperts" [viewStage]="'new'"
              [opportunity]="opp" [isFileDownloadAuthorised]="isFileDownloadAuthorised" [isClientUser]="isClientUser"
              [isExpertApprovalRequired]="vmE.expertApprovalRequired" [showEchoAsk]="showEchoAsk">
            </app-expert-list>
          </div>
        </mat-tab>

        <mat-tab *ngIf="vmE.experts.acceptedExperts as acceptedExperts">
          <ng-template mat-tab-label>
            <span data-test-id="opportunity-upcoming-connections-tab">Upcoming Connections</span>
            <span class="count secondaryColorBG primaryColor" *ngIf="acceptedExperts.length"
              data-test-id="opportunity-upcoming-connections-count">{{acceptedExperts.length}}</span>
          </ng-template>
          <div class="accepted-experts">
            <app-expert-list [expertLoadingIds]="expertLoadingIds$ | async" [experts]="acceptedExperts"
              [viewStage]="'accepted'" [opportunity]="opp" [isFileDownloadAuthorised]="isFileDownloadAuthorised"
              [isClientUser]="isClientUser" [isExpertApprovalRequired]="vmE.expertApprovalRequired"
              [showEchoAsk]="showEchoAsk">
            </app-expert-list>
          </div>
        </mat-tab>

        <mat-tab *ngIf="vmE.experts.completedConnections as completedConnections">
          <ng-template mat-tab-label>
            <span data-test-id="opportunity-completed-connections-tab">Completed Connections</span>
            <span class="count secondaryColorBG primaryColor" *ngIf="completedConnections.length"
              data-test-id="opportunity-completed-connections-count">{{completedConnections.length}}</span>
          </ng-template>
          <div class="accepted-experts">
            <app-expert-list [expertLoadingIds]="expertLoadingIds$ | async" [experts]="completedConnections"
              [viewStage]="'completed'" [opportunity]="opp" [isFileDownloadAuthorised]="isFileDownloadAuthorised"
              [isClientUser]="isClientUser" [isExpertApprovalRequired]="vmE.expertApprovalRequired"
              [showEchoAsk]="showEchoAsk">
            </app-expert-list>
          </div>
        </mat-tab>

        <mat-tab *ngIf="vmE.experts.declinedExperts as declinedExperts">
          <ng-template mat-tab-label>
            <span data-test-id="opportunity-rejected-profiles-tab">Rejected Profiles</span>
            <span class="count secondaryColorBG primaryColor" *ngIf="declinedExperts.length"
              data-test-id="opportunity-rejected-profiles-count">{{declinedExperts.length}}</span>
          </ng-template>
          <div class="declined-experts">
            <app-expert-list [expertLoadingIds]="expertLoadingIds$ | async" [experts]="declinedExperts"
              [viewStage]="'declined'" [opportunity]="opp" [isFileDownloadAuthorised]="isFileDownloadAuthorised"
              [isClientUser]="isClientUser" [isExpertApprovalRequired]="vmE.expertApprovalRequired"
              [showEchoAsk]="showEchoAsk">
            </app-expert-list>
          </div>
        </mat-tab>

        <mat-tab *ngIf="showEchoAsk">
          <ng-template mat-tab-label>
            <span class="flex flex-row items-center gap-2" data-test-id="opportunity-echo-ask-tab">
              <app-sparkle></app-sparkle>
              Project Insights
            </span>
          </ng-template>
          <app-assistant [opportunityId]="opp.opportunityId"></app-assistant>
        </mat-tab>

      </mat-tab-group>
    </div>
  </ng-container>

</ng-template>

<ng-template #approvalRequiredBannerTmpl>
  <div class="warning-box" data-test-id="opportunity-compliance-approval-required-banner">
    <div class="flex gap-2">
      <mat-icon>lock</mat-icon>
      <div class="flex flex-col gap-2">
        <b data-test-id="opportunity-compliance-approval-required-title">Compliance approval required</b>
        <p data-test-id="opportunity-compliance-approval-required-message">
          All experts in this project require approval prior to scheduling for compliance reasons.
        </p>
      </div>
    </div>
  </div>
</ng-template>